import { type PaginationProps } from '@prezly/theme-kit-nextjs';

import { PUBLICITY_ASSETS_CATEGORY_SLUG } from '@/utils';
import type { StoryWithImage } from 'types';

import InfiniteStories from '../InfiniteStories';
import Layout from '../Layout';

interface Props {
    stories: StoryWithImage[];
    pagination: PaginationProps;
}

function Stories({ stories, pagination }: Props) {
    return (
        <Layout title={(metaTitle) => `Home | ${metaTitle}`}>
            <InfiniteStories
                initialStories={stories}
                pagination={pagination}
                filterQuery={{ 'category.slug': { $none: [PUBLICITY_ASSETS_CATEGORY_SLUG] } }}
            />
        </Layout>
    );
}

export default Stories;
